import MapAndInfo from "./map_and_info/MapAndInfo";
import "./CalculatorPage.css";
import PriceAndForm from "./price_and_forms/PriceAndForm";
import CalculatorAdvan from "./calculator_advan/CalculatorAdvan";
import ContactUs from "../home/home components/ContactUs";
import RecentSells from "../home/home components/RecentSells";
import Carousel from "../../Carousel/Carousel";
import { connect } from "react-redux";
import CalculatorContext from "./CalculatorContext";

const CalCulatorPage = (props: any) => {
  return (
    <div className="calculator-page-container">
      <head>
        <title>Verkoopgarantie.nu | Bod aanvragen | Snel verkopen</title>
        <meta
          name="description"
          content="Snel een bod voor je woning. Met verkoopgarantie verkoop je binnen 1 week je woning tegen het beste bod."
        />
      </head>
      <CalculatorContext.Provider value={props.data}>
        <div className="calculator-info-price-container">
          <div className="calculator-info-price-div">
            <MapAndInfo />
            <PriceAndForm />
          </div>
        </div>
        <CalculatorAdvan />
        <ContactUs />
        <RecentSells />
        <Carousel />
      </CalculatorContext.Provider>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return { data: state.calculator };
};
export default connect(mapStateToProps)(CalCulatorPage);
