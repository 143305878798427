import React from "react";
import "./NotFound.css";
import img from "./404.png";
import { Button } from "@material-ui/core";
import history from "../../../dependencies/history/history";
const NotFound = () => {
  return (
    <div className="not-found-container">
      <head>
        <title>404 | Not found ! </title>
      </head>
      <img
        className="not-found-img"
        src={img}
        alt="404, the page can not be found"
      ></img>
      <div className="not-found-text">Uh-oh, page not found…</div>
      <Button
        onClick={() => {
          window.scrollTo({ top: 0 });
          history.push("/");
        }}
        className="not-found-btn"
      >
        go back to home{" "}
      </Button>
    </div>
  );
};

export default NotFound;
