import React from "react";
import { useTranslation } from "react-i18next";
import "./landing.css";
const Landing = () => {
  const { t } = useTranslation();
  return (
    <div className="landing-container">
      <h1 className="landing-container-title">{t("Thanks.header")}</h1>
      <div className="landing-container-description">
        {t("Thanks.description")}
      </div>
    </div>
  );
};

export default Landing;
