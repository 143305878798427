import history from "../../dependencies/history/history";
import { baseURL } from "../../utils/Urls";
import mich from "../axios/axios";
import {
  LOADING_CONTACT,
  LOADING_END_CONTACT,
  SEND_INFO_CONTACT,
} from "./Actions_types";
import { setSnackbar } from "./snackbar";

export interface ContactFormTypes {
  FirstName?: string;
  LastName?: string;
  EmailAddress: string;
  PhoneNumber: string;
  Message?: string;
}
export const sendEmail =
  (contactData: ContactFormTypes) => async (dispatch: any) => {
    dispatch({ type: LOADING_CONTACT });
    const response = await mich
      .post(baseURL + "/contact", contactData)
      .catch((error: any) => {
        dispatch({ type: LOADING_END_CONTACT });
      });

    dispatch({ type: SEND_INFO_CONTACT, payload: response });
    if (response) {
      if (response.status === 200) {
        window.scrollTo({ top: 0 });

        dispatch(
          setSnackbar(
            true,
            "success",
            `${
              localStorage.getItem("i18nextLng") === "en"
                ? "Your request was sent successfully "
                : "Uw aanvraag is succesvol verzonden"
            }`
          )
        );
      }
    }
  };
