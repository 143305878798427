import {
  Button,
  OutlinedInput,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import React, { FC, useContext, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import check from "../../../../assets/images/check.svg";
import { calc_redux_Type } from "../../../../redux/reducer/calculator";
import CalculatorContext from "../CalculatorContext";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import "./PriceAndForm.css";
import NumberFormat from "react-number-format";
import { connect, useDispatch } from "react-redux";
import { calc_email_type } from "../../../../redux/actions/Calculator_actions";
import { sendCalcEmail } from "../../../../redux/actions/Calculator_actions";
import { setSnackbar } from "../../../../redux/actions/snackbar";
import CustomError from "../../../pages/home/home components/CustomError";
interface Props {
  sendCalcEmail?: (contactData: calc_email_type) => void;
}
const PriceAndForm = (props: any) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [modalError, setModalError] = useState(false);
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    CalculateRequestId: `${sessionStorage.getItem("requestId")}`,
    EmailAddress: ``,
    Message: "",
    PhoneNumber: ``,
    Name: "",
  });
  const data: calc_redux_Type = useContext(CalculatorContext);
  const { t } = useTranslation();

  const setName = (data: string) => {
    setFormData({ ...formData, Name: data });
  };
  const setPhoneNumber = (data: string) => {
    setFormData({ ...formData, PhoneNumber: data });
  };
  const setEmail = (data: string) => {
    setFormData({ ...formData, EmailAddress: data });
  };
  const setMassage = (data: string) => {
    setFormData({ ...formData, Message: data });
  };
  const handleSubmit = (data: calc_email_type) => {
    data.redirect = true;
    if (props.sendCalcEmail) {
      props.sendCalcEmail(data);
      setFormData({
        CalculateRequestId: `${sessionStorage.getItem("requestId")}`,
        EmailAddress: "",
        Message: "",
        Name: "",
        PhoneNumber: "",
      });
    }
  };
  const emailIsValid = (email: string) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmitUserInfo = async () => {
    if (formData.EmailAddress === "" || formData.PhoneNumber === "") {
      // dispatch(
      //   setSnackbar(
      //     true,
      //     "error",
      //     `${
      //       localStorage.getItem("i18nextLng") === "en"
      //         ? "Please fill out all the fields"
      //         : "Gelieve alle velden in te vullen"
      //     }`
      //   )
      // );
      setErrorText(
        localStorage.getItem("i18nextLng") === "en"
          ? "Please fill out all the fields"
          : "Gelieve alle velden in te vullen"
      );
      setModalError(true);
    } else if (!emailIsValid(formData.EmailAddress)) {
      // dispatch(
      //   setSnackbar(
      //     true,
      //     "error",
      //     `${
      //       localStorage.getItem("i18nextLng") === "en"
      //         ? "Please Enter the valid Email"
      //         : "Voer het geldige e-mailadres in"
      //     }`
      //   )
      // );
      setErrorText(
        localStorage.getItem("i18nextLng") === "en"
          ? "Please Enter the valid Email"
          : "Voer het geldige e-mailadres in"
      );
      setModalError(true);
    } else if (formData.PhoneNumber.length < 9) {
      // dispatch(
      //   setSnackbar(
      //     true,
      //     "error",
      //     `${
      //       localStorage.getItem("i18nextLng") === "en"
      //         ? "Please Enter the valid Phone Number"
      //         : "Voer het geldige telefoonnummer in"
      //     }`
      //   )
      // );
      setErrorText(
        localStorage.getItem("i18nextLng") === "en"
          ? "Please Enter the valid Phone Number"
          : "Voer het geldige telefoonnummer in"
      );
      setModalError(true);
    } else {
      setLoading(true);
      const token = await recaptchaRef!.current!.executeAsync();
      let result = await props.sendCalcEmail({
        CalculateRequestId: formData.CalculateRequestId,
        EmailAddress: formData.EmailAddress,
        PhoneNumber: formData.PhoneNumber,
        RecaptchaToken: token,
      });
      setOpenModal(false);
    }
  };
  return (
    <div className="paf-container">
      <Modal
        open={modalError}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="calculation-modal"
      >
        <div className="modal-content-error-container">
          <CustomError onClick={() => setModalError(false)} text={errorText} />
        </div>
      </Modal>
      <Modal
        open={openModal}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="calculation-modal"
      >
        <div className={`calculation-modal-content-bg`}>
          <div className="modal-content-email-step-container">
            <div className="modal-content-email-step">
              <OutlinedInput
                value={formData.PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={t("calc_page.form_2")}
                autoComplete="off"
              />
              <OutlinedInput
                value={formData.EmailAddress}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("calc_page.form_3")}
                autoComplete="off"
              />
            </div>
            <Button className="modal-btn" onClick={handleSubmitUserInfo}>
              {loading ? (
                <CircularProgress style={{ color: "white" }} />
              ) : (
                t("Zie indicatie van de verkoopprijs")
              )}
            </Button>
            <p>
              {t("calc_page.privacy_description")}{" "}
              <a
                className="privacy-anchor"
                rel="noreferrer"
                target="_blank"
                href="https://www.verkoopgarantie.nu/static/media/Privacy-Statement-Verkoopgarantie.nu_-1_2.00e7b86e.pdf"
              >
                {t("calc_page.privacy_link_text")}
              </a>
            </p>
          </div>
        </div>
      </Modal>
      <div className="paf-first-letter">
        {t("calc_page.price_first_letter")}
      </div>
      <div className="paf-price-range">
        {data.loading ? (
          <Typography component="div" key={"h3"} variant={"h3"}>
            {
              <Skeleton
                style={{ backgroundColor: "#039673", width: "250px" }}
              />
            }
          </Typography>
        ) : (
          <div>
            <NumberFormat
              value={
                data.calcInfo.minConfidence
                  ? data.calcInfo.minConfidence
                  : sessionStorage.getItem("minConfidence")
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"€ "}
            />{" "}
            -{" "}
            <NumberFormat
              value={
                data.calcInfo.maxConfidence
                  ? data.calcInfo.maxConfidence
                  : sessionStorage.getItem("maxConfidence")
              }
              displayType={"text"}
              thousandSeparator={true}
              prefix={"€ "}
            />
          </div>
        )}
      </div>
      <div className="paf-description">{t("calc_page.price_description")}</div>
      <div className="paf-options-cntainer">
        <div className="paf-options-div">
          <img src={check} alt="" />
          <div className="paf-options-description">
            {t("calc_page.price_options.1")}
          </div>
        </div>
        <div className="paf-options-div">
          <img src={check} alt="" />
          <div className="paf-options-description">
            {t("calc_page.price_options.2")}
          </div>
        </div>
        <div className="paf-options-div" style={{ marginBottom: "20px" }}>
          <img src={check} alt="" />
          <div className="paf-options-description">
            {t("calc_page.price_options.3")}
          </div>
        </div>
        {!open && (
          <div>
            <Button
              className="privacy-open-button"
              onClick={() => setOpen(true)}
              variant="contained"
            >
              {localStorage.getItem("i18nextLng") === "en"
                ? "REQUEST NON-BINDING OFFER"
                : "VRIJBLIJVENDE BOD AANVRAGEN"}
            </Button>
          </div>
        )}
        {open && (
          <>
            <div className="paf-forms-1-div">
              <OutlinedInput
                value={formData.Name}
                onChange={(e) => setName(e.target.value)}
                style={{ marginTop: "15px" }}
                className="paf-1-field"
                placeholder={t("calc_page.form_1")}
                autoComplete="off"
              />
              <OutlinedInput
                value={formData.PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{ marginTop: "15px", marginRight: "0" }}
                className="paf-1-field"
                placeholder={t("calc_page.form_2")}
                autoComplete="off"
              />
            </div>
            <OutlinedInput
              value={formData.EmailAddress}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginTop: "15px" }}
              className="paf-1-field"
              placeholder={t("calc_page.form_3")}
              autoComplete="off"
            />
            <OutlinedInput
              value={formData.Message}
              onChange={(e) => setMassage(e.target.value)}
              style={{ marginTop: "15px" }}
              multiline
              rowsMax={4}
              className="paf-1-field paf-2-field"
              placeholder={t("calc_page.form_4")}
              autoComplete="off"
            />
            <div className="privacy-container">
              <Button
                onClick={() => {
                  if (
                    formData.Name === "" ||
                    formData.PhoneNumber === "" ||
                    formData.CalculateRequestId === "" ||
                    formData.EmailAddress === ""
                  ) {
                    dispatch(
                      setSnackbar(
                        true,
                        "error",
                        `${
                          localStorage.getItem("i18nextLng") === "en"
                            ? "Please fill out all the fields !"
                            : "Gelieve alle velden in te vullen !"
                        }`
                      )
                    );
                  } else {
                    handleSubmit(formData);
                  }
                }}
                className="privacy-button"
                variant="contained"
              >
                {t("calc_page.privacy_button")}
              </Button>
            </div>
          </>
        )}

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey="6LcnR8QaAAAAABeub8vW-lFCxrrHRuk1ZNln6JTx"
        />
      </div>
    </div>
  );
};

export default connect(null, { sendCalcEmail })(PriceAndForm);
