import React, { useContext, useEffect } from "react";
import "./Map.css";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { calc_redux_Type } from "../../../../../redux/reducer/calculator";
import CalculatorContext from "../../CalculatorContext";
import { CircularProgress } from "@material-ui/core";
interface MapPropsTypes {
  lat: number;
  long: number;
}
const Map = (props: MapPropsTypes) => {
  const data: calc_redux_Type = useContext(CalculatorContext);
  if (data.loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ color: "#3B3B3B" }} />
      </div>
    );
  }
  return (
    <div style={{ width: "100%", height: "314px", position: "sticky" }}>
      <MapContainer
        style={{ width: "100%", height: "314px" }}
        minZoom={4}
        center={[props.lat, props.long]}
        zoom={13}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[props.lat, props.long]}></Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
