import React, { useEffect } from "react";
import "./MyAccordion.css";
import closeIcon from "../../assets/images/CLOSE.png";
import openIcon from "../../assets/images/OPEN.png";
import { withStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
const MyAccordion = (props) => {
  const { classes } = props;
  const { isDefaultOpen } = props;
  const [expanded, setExpanded] = React.useState(false);
  useEffect(() => {
    if (isDefaultOpen) {
      setExpanded(true);
    }
  }, [isDefaultOpen]);
  const handleChange = () => {
    setExpanded(expanded ? false : true);
  };
  return (
    <div className="accordion-contianer">
      <Accordion
        expanded={expanded}
        onChange={() => {
          handleChange();
        }}
        className="accordion-div"
      >
        <AccordionSummary
          classes={{
            expandIcon: classes.expandIcon,
            expanded: classes.expanded,
          }}
          className="accordion-title"
          expandIcon={
            expanded ? (
              <img alt="" src={closeIcon} />
            ) : (
              <img alt="" src={openIcon} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="accordion-title-text">
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ border: "1px solid #CCCCCC " }}
          className="accordion-description"
        >
          <Typography className="accordion-description-text">
            {props.description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
const styles = (theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expandIcon: {
    "&$expanded": {
      transform: " rotate(0deg)",
      transition: ".2s",
    },
  },
  expanded: {},
});
export default withStyles(styles)(MyAccordion);
