import React from "react";
import "./App.css";
import "./index.css";
import { Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import history from "./dependencies/history/history";
import HomePage from "./components/pages/home/HomePage";
import CalCulatorPage from "./components/pages/calculator/CalCulatorPage";
import Amsterdam from "./components/pages/Amesterdam/Amsterdam";
import Rotterdam from "./components/pages/Rotterdam/Rotterdam";
import Den_Hag from "./components/pages/Den_Hag/Den_Hag";
import Utrecht from "./components/pages/Utrecht/Utrecht";
import Eindhoven from "./components/pages/Eindhoven/Eindhoven";
import Contact from "./components/pages/Contact/Contact";
import Waroom from "./components/pages/Waroom/Waroom";
import Snackbar from "./components/Snackbar/Snackbar";
import Landing from "./components/pages/landing/landing";
import NotFound from "./components/pages/NotFound/NotFound";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const App = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (!localStorage.getItem("hasChangedLanguage")) {
      i18n.changeLanguage("nl");
    }
  }, []);
  return (
    <div>
      <Header />
      <div style={{ height: "3.1rem" }} />
      <Snackbar />
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/bod-aanvragen" exact component={CalCulatorPage} />
          <Route path="/huis-verkopen-amsterdam" exact component={Amsterdam} />
          <Route path="/huis-verkopen-rotterdam" exact component={Rotterdam} />
          <Route path="/huis-verkopen-den-haag" exact component={Den_Hag} />
          <Route path="/huis-verkopen-utrecht" exact component={Utrecht} />
          <Route path="/huis-verkopen-eindhoven" exact component={Eindhoven} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/waroom" exact component={Waroom} />
          <Route path="/bedankt" exact component={Landing} />
          <Route exact render={NotFound} />
        </Switch>
      </Router>

      <Footer />
    </div>
  );
};

export default App;
