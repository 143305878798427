import { SET_SNACKBAR } from "../Actions_types";

export const setSnackbar = (
  snackbarOpen: boolean,
  snackbarType = "success",
  snackbarMessage: any = ""
) => ({
  type: SET_SNACKBAR,
  snackbarOpen,
  snackbarType,
  snackbarMessage,
});
