import React from "react";
import Slider from "react-slick";
import friends from "../../assets/images/friends.png";
import star4 from "../../assets/images/stars4.svg";
import star45 from "../../assets/images/stars4.5.svg";
import star5 from "../../assets/images/stars5.svg";
import willem from "../../assets/images/profiles/willem.png";
import linda from "../../assets/images/profiles/linda.png";
import erik from "../../assets/images/profiles/erik.png";
import david from "../../assets/images/profiles/david.png";
import shalisa from "../../assets/images/profiles/shalisa.png";
import daniel from "../../assets/images/profiles/daniel.png";
import "./Carousel.css";
import { useTranslation } from "react-i18next";
const Carousel = () => {
  const { t } = useTranslation();
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "470px",
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "320px",
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 920,
        settings: {
          centerPadding: "150px",
        },
      },
      {
        breakpoint: 720,
        settings: {
          centerPadding: "90px",
        },
      },
      {
        breakpoint: 520,
        settings: {
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerPadding: "50px",
        },
      },
      {
        breakpoint: 420,
        settings: {
          centerPadding: "50px",
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="carousel-container">
      <img className="carousel-background" alt="" src={friends}></img>
      <div className="carousel-div">
        {" "}
        <div className="container">
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <Slider className="slick-track" {...settings}>
            <div>
              <div className="carousel-content">
                <img alt="" className="carousel-rate" src={star4}></img>
                <div className="carousel-item-head">
                  {t("carousel.1.header")}
                </div>
                <div className="carousel-item-description">
                  {t("carousel.1.description")}
                </div>
                <div className="devider" />
                <img alt="" src={willem} className="carousel-profile" />
                <div className="carousel-name">willem</div>
              </div>
            </div>

            <div>
              <div className="carousel-content">
                <img alt="" className="carousel-rate" src={star5}></img>
                <div className="carousel-item-head">
                  {t("carousel.2.header")}
                </div>
                <div className="carousel-item-description">
                  {t("carousel.2.description")}
                </div>
                <div className="devider" />
                <img alt="" src={linda} className="carousel-profile" />
                <div className="carousel-name">Linda</div>
              </div>
            </div>

            <div>
              <div className="carousel-content">
                <img alt="" className="carousel-rate" src={star45}></img>
                <div className="carousel-item-head">
                  {" "}
                  {t("carousel.3.header")}
                </div>
                <div className="carousel-item-description">
                  {t("carousel.3.description")}
                </div>
                <div className="devider" />
                <img alt="" src={erik} className="carousel-profile" />
                <div className="carousel-name">Erik</div>
              </div>
            </div>
            <div>
              <div className="carousel-content">
                <img alt="" className="carousel-rate" src={star5}></img>
                <div className="carousel-item-head">
                  {t("carousel.4.header")}
                </div>
                <div className="carousel-item-description">
                  {t("carousel.4.description")}
                </div>
                <div className="devider" />
                <img alt="" src={david} className="carousel-profile" />
                <div className="carousel-name">David</div>
              </div>
            </div>
            <div>
              <div className="carousel-content">
                <img alt="" className="carousel-rate" src={star45}></img>
                <div className="carousel-item-head">
                  {t("carousel.5.header")}
                </div>
                <div className="carousel-item-description">
                  {t("carousel.5.description")}
                </div>
                <div className="devider" />
                <img alt="" src={shalisa} className="carousel-profile" />
                <div className="carousel-name">Shalisa</div>
              </div>
            </div>
            <div>
              <div className="carousel-content">
                <img alt="" className="carousel-rate" src={star45}></img>
                <div className="carousel-item-head">
                  {t("carousel.6.header")}
                </div>
                <div className="carousel-item-description">
                  {t("carousel.6.description")}
                </div>
                <div className="devider" />
                <img alt="" src={daniel} className="carousel-profile" />
                <div className="carousel-name">Daniel</div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default Carousel;
