import React from 'react';
import {LinearProgress } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import {  withStyles } from '@material-ui/core/styles';
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 19,
    borderRadius: 100,
  },
  colorPrimary: {
    backgroundColor:'#747474'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#FA7F28',
  },
}))(LinearProgress);

const ProgressItem=({progress,checked,style})=>{
return(
      <div className='calculation-modal-progress' style={style}>
            <div className='calculation-modal-progress-bar'>
                <BorderLinearProgress variant="determinate" value={progress} />
            </div>
            <div className='calculation-modal-progress-check-icon'>
              <Checkbox
                icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />}
                checked={checked}
              />
            </div>
    </div>
)
}
export default ProgressItem;