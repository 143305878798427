import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import { setSnackbar } from "../../redux/actions/snackbar";

const CustomizedSnackbars = () => {
  const dispatch = useDispatch();
  const snackbarOpen = useSelector((state) => state.snackbar.snackbarOpen);
  const snackbarType = useSelector((state) => state.snackbar.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.snackbar.snackbarMessage
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiSnackbarContent-root": {
        "background-color": snackbarType === "error" ? "#F44336" : "#4CAF50",
      },
    },
  }));
  const classes = useStyles();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSnackbar(false, snackbarType, snackbarMessage));
  };

  return (
    <div>
      <Snackbar
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbarOpen}
        autoHideDuration={3500}
        onClose={handleClose}
        message={snackbarMessage}
      />
    </div>
  );
};

export default CustomizedSnackbars;
