import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import ad2 from "../../../../assets/images/calculateb2.png";
import check from "../../../../assets/images/check.svg";
import "./SecAdvertise.css";
const SecAdvertise = () => {
  const { t } = useTranslation();
  return (
    <div className="sec-ad-container">
      <img src={ad2} className="ad-2-img" alt="" />
      <div className="ad-2-info-div">
        <h1 className="ad-2-title">{t("ad-2.header")}</h1>
        <div className="ad-2-description">{t("ad-2.description")}</div>
        <div className="ad-2-items-container">
          <img src={check} alt="" />
          <div className="ad-2-items-text"> {t("ad-2.item-1")}</div>
        </div>
        <div className="ad-2-items-container">
          <img src={check} alt="" />
          <div className="ad-2-items-text">{t("ad-2.item-2")}</div>
        </div>
        <div className="ad-2-items-container">
          <img src={check} alt="" />
          <div className="ad-2-items-text">{t("ad-2.item-3")}</div>
        </div>{" "}
        <Button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="ad-2-button"
          variant="contained"
        >
          {t("ad-2.button")}
        </Button>
      </div>
    </div>
  );
};

export default SecAdvertise;
