import {
  GET_CALCULATE as GET_DATA,
  LOADING_CALCULATE as LOADING,
  LOADING_END_CALCULATE as LOADING_END,
} from "../actions/Actions_types";
import { calc_redux_Type } from "./calculator";
export const INITIAL_STATE: calc_redux_Type = {
  loading: false,
  calcInfo: {
    requestId: "",
    postCode: "",
    houseNumber: "",
    houseAddition: null,
    city: "",
    street: "",
    houseType: "",
    buildYear: "",
    innerSurfaceArea: "",
    outerSurfaceArea: "",
    energyLabel: "",
    longitude: "",
    latitude: "",
    minConfidence: 0,
    maxConfidence: 0,
  },
};
const reducer = (state: calc_redux_Type = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case GET_DATA:
      return {
        ...state,
        calcInfo: action.payload?.data?.data,
        loading: false,
      };
    case LOADING_END:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default reducer;
