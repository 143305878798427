import history from "../../dependencies/history/history";
import { baseURL } from "../../utils/Urls";
import mich from "../axios/axios";
import { calc_response_Type } from "../reducer/calculator";
import {
  CALC_EMAIL_LOADING,
  CALC_EMAIL_LOADING_END,
  GET_CALCULATE,
  LOADING_CALCULATE,
  LOADING_END_CALCULATE,
} from "./Actions_types";
import { setSnackbar } from "./snackbar";

export interface FormTypes {
  PostalCode: string;
  HouseNumber: number;
  Addition: string;
  RecaptchaToken: string;
}
const SetDatainSession = (data: calc_response_Type) => {
  sessionStorage.setItem("buildYear", data.buildYear);
  sessionStorage.setItem("city", data.city);
  sessionStorage.setItem("energyLabel", data.energyLabel);
  sessionStorage.setItem("houseAddition", `${data.houseAddition}`);
  sessionStorage.setItem("houseNumber", data.houseNumber);
  sessionStorage.setItem("houseType", data.houseType);
  sessionStorage.setItem("innerSurfaceArea", data.innerSurfaceArea);
  sessionStorage.setItem("latitude", data.latitude);
  sessionStorage.setItem("longitude", data.longitude);
  sessionStorage.setItem("maxConfidence", `${data.maxConfidence}`);
  sessionStorage.setItem("minConfidence", `${data.minConfidence}`);
  sessionStorage.setItem("outerSurfaceArea", data.outerSurfaceArea);
  sessionStorage.setItem("postCode", data.postCode);
  sessionStorage.setItem("requestId", data.requestId);
  sessionStorage.setItem("street", data.street);
};
export const sendCalculateInfo =
  (calcData: FormTypes) => async (dispatch: any) => {
    dispatch({ type: LOADING_CALCULATE });
    return mich
      .post(baseURL + "/Calculator", calcData, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
        },
      })
      .then((response) => {
        dispatch({ type: GET_CALCULATE, payload: response });
        if (response) {
          SetDatainSession(response.data.data);
          if (response.status === 200) {
            history.push("/bod-aanvragen");
          }
        }

        dispatch({ type: LOADING_END_CALCULATE });
        return response;
      })
      .catch((error: any) => {
        dispatch({ type: LOADING_END_CALCULATE });
        // dispatch(
        //   setSnackbar(
        //     true,
        //     "error",
        //     `${
        //       localStorage.getItem("i18nextLng") === "en"
        //         ? "An error occurred. Please double check the characters"
        //         : "Er is een fout opgetreden. Controleer de karakters dubbel"
        //     }`
        //   )
        // );
        return error;
      });
  };
export interface calc_email_type {
  CalculateRequestId?: string;
  Name?: string;
  EmailAddress: string;
  PhoneNumber: string;
  Message?: string;
  redirect?: boolean;
}
export const sendCalcEmail =
  (calcFormDataData: calc_email_type) => async (dispatch: any) => {
    dispatch({ type: CALC_EMAIL_LOADING });
    return mich
      .post(baseURL + "/calculator/order", calcFormDataData)
      .then((response) => {
        if (response) {
          if (response.status === 200) {
            window.scrollTo({ top: 0 });
            if (calcFormDataData.redirect) {
              history.push("/bedankt");
            }
            sessionStorage.setItem(
              "EmailAddress",
              calcFormDataData.EmailAddress
            );
            sessionStorage.setItem("PhoneNumber", calcFormDataData.PhoneNumber);
            //history.push("/bod-aanvragen");

            // dispatch(
            //   setSnackbar(
            //     true,
            //     "success",
            //     `${
            //       localStorage.getItem("i18nextLng") === "en"
            //         ? "Your request was sent successfully "
            //         : "Uw aanvraag is succesvol verzonden"
            //     }`
            //   )
            // );
          }
          return response;
        }
      })
      .catch((error: any) => {
        dispatch({ type: CALC_EMAIL_LOADING_END });
        setSnackbar(
          true,
          "success",
          `${
            localStorage.getItem("i18nextLng") === "en"
              ? "An error occurred. Please try again"
              : "Er is een fout opgetreden. Probeer het a.u.b. opnieuw"
          }`
        );
        return error;
      });
  };
