import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  OutlinedInput,
  Modal,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import "./CalculationForm.css";
import {
  sendCalculateInfo,
  sendCalcEmail,
} from "../../../../redux/actions/Calculator_actions";
import { connect, useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/actions/snackbar";
import ReCAPTCHA from "react-google-recaptcha";
import { LOADING_CALCULATE } from "../../../../redux/actions/Actions_types";
import ProgressItem from "./ProgressItem";
import CheckBox from "./CheckBox";
import BackgroundIcon from "../../../../assets/images/waiting-background.svg";
import CustomError from "./CustomError";
let timer2: ReturnType<typeof setTimeout>;
let timer1: ReturnType<typeof setTimeout>;
let timer3: ReturnType<typeof setTimeout>;
let timer4: ReturnType<typeof setTimeout>;
let timer5: ReturnType<typeof setTimeout>;
let timer6: ReturnType<typeof setTimeout>;
const CalculationForm = (props: any) => {
  
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const [progress, setProgress] = React.useState(0);
  const [progressbar, setProgressBar] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const [progress2, setProgress2] = React.useState(0);
  const [progress3, setProgress3] = React.useState(0);
  const [progress4, setProgress4] = React.useState(0);
  const [progress5, setProgress5] = React.useState(0);
  const [modalError, setModalError] = useState(false);
 

  React.useEffect(() => {
    if (open) {
      timer1 = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 25
        );
      }, 10);
      setTimeout(function () {
        timer2 = setInterval(() => {
          setProgressBar((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 5
          );
        }, 10);
        return () => {
          //setProgressBar(0)
        };
      }, 250);
      setTimeout(function () {
        setChecked(true);
        timer3 = setInterval(() => {
          setProgress2((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 25
          );
        }, 10);
        return () => {};
      }, 500);
      setTimeout(function () {
        setChecked1(true);
        timer4 = setInterval(() => {
          setProgress3((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 25
          );
        }, 10);
        return () => {
          //clearInterval(timer);
        };
      }, 1000);
      setTimeout(function () {
        setChecked2(true);
        timer5 = setInterval(() => {
          setProgress4((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 25
          );
        }, 10);
        return () => {
          //clearInterval(timer);
        };
      }, 1500);
      setTimeout(function () {
        setChecked3(true);
        timer6 = setInterval(() => {
          setProgress5((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + 25
          );
        }, 10);
        return () => {
          //clearInterval(timer);
        };
      }, 2500);
    } else {
      clearInterval(timer1);
      clearInterval(timer2);
      clearInterval(timer3);
      clearInterval(timer4);
      clearInterval(timer5);
      clearInterval(timer6);
    }
    return () => {
      setChecked(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setProgress(0);
      setProgress2(0);
      setProgress3(0);
      setProgress4(0);
      setProgress5(0);
      setProgressBar(0);
      clearInterval(timer1);
      clearInterval(timer2);
      clearInterval(timer3);
      clearInterval(timer4);
      clearInterval(timer5);
      clearInterval(timer6);
    };
  }, [open, modalError]);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const dispatch = useDispatch();
  const [formData, formChange] = React.useState({
    PostalCode: "",
    HouseNumber: 0,
    Addition: "",
  });
  React.useEffect(() => {
    if (progress5 === 100) {
      setChecked4(true);
    }
  }, [progress5]);

  const { t } = useTranslation();
  interface FormTypes {
    PostalCode: string;
    HouseNumber: number;
    Addition: string;
  }
  function postcodeChange(data: string) {
    formChange({ ...formData, PostalCode: data.replace(/\s/g, "") });
  }
  function houseNumberChange(data: number) {
    formChange({ ...formData, HouseNumber: data });
  }
  function additionChange(data: string) {
    formChange({ ...formData, Addition: data });
  }
  const handleClick = async (data: FormTypes) => {
    dispatch({ type: LOADING_CALCULATE });
    const token = await recaptchaRef!.current!.executeAsync();
    let result = await props.sendCalculateInfo({
      PostalCode: formData.PostalCode,
      HouseNumber: formData.HouseNumber,
      Addition: formData.Addition,
      RecaptchaToken: token,
    });
    setOpen(false);
    setChecked(false);
    setChecked1(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    setProgress(0);
    setProgress2(0);
    setProgress3(0);
    setProgress4(0);
    setProgress5(0);
    setProgressBar(0);
    if (result && result.response && result.response.status === 404) {
      setOpen(false);
      setChecked(false);
      setChecked1(false);
      setChecked2(false);
      setChecked3(false);
      setChecked4(false);
      setProgress(0);
      setProgress2(0);
      setProgress3(0);
      setProgress4(0);
      setProgress5(0);
      setProgressBar(0);
      setModalError(true);
    }
  };

  const handleOpenModal = () => {
    handleClick(formData);
    setOpen(true);
    setStep(0);
  };

  return (
    <div className="calculation-fields-div">
      <Modal
        open={modalError}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="calculation-modal"
      >
        <div className="modal-content-error-container">
          <CustomError
            onClick={() => setModalError(false)}
            text={
              localStorage.getItem("i18nextLng") === "en"
                ? "It looks like the given combination is not recognized by our database. Please try again or contact us: +31646366335 | info@verkoopgarantie.nu"
                : "Het ziet er naar uit dat de opgegeven combinatie niet herkend wordt door onze database. Probeer het nogmaals of neem contact met ons op: +31646366335 | info@verkoopgarantie.nu"
            }
          />
        </div>
      </Modal>
      <Modal
        open={open}
        disableBackdropClick
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="calculation-modal"
      >
        <div className={`calculation-modal-content`}>
          <div>
            <img src={BackgroundIcon} alt="" className="background-image" />
            <div style={{ marginTop: 60 }}>
              <h1>Calculating</h1>
              {progress5 > 25 ? (
                <CheckBox />
              ) : (
                <CircularProgress
                  style={{ color: "#000" }}
                  thickness={2}
                  variant="determinate"
                  value={progressbar}
                />
              )}
            </div>
            <div className="progress">
              <div className="calculation-modal-content-fixed-box"></div>

              <ProgressItem
                progress={progress}
                checked={checked}
                style={{ marginLeft: 0 }}
              />
              <ProgressItem
                progress={progress2}
                checked={checked1}
                style={{ marginLeft: 7 }}
              />

              <ProgressItem
                progress={progress3}
                checked={checked2}
                style={{ marginLeft: 12 }}
              />

              <ProgressItem
                progress={progress4}
                checked={checked3}
                style={{ marginLeft: 17 }}
              />

              <ProgressItem
                progress={progress5}
                checked={checked4}
                style={{ marginLeft: 22 }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <OutlinedInput
        className="postcode-field"
        onChange={(e) => postcodeChange(e.target.value)}
        placeholder={t("calculator fields.first field")}
        autoComplete="off"
      />
      <OutlinedInput
        className="house-number-field"
        onChange={(e) => houseNumberChange(parseInt(e.target.value))}
        placeholder={t("calculator fields.second field")}
        autoComplete="off"
      />
      <OutlinedInput
        className="house-number-field"
        onChange={(e) => additionChange(e.target.value)}
        placeholder={t("calculator fields.third field")}
        autoComplete="off"
      />
      <Button
        type="submit"
        onClick={() => {
          formData.PostalCode === ""
            ? dispatch(
                setSnackbar(
                  true,
                  "error",
                  `${
                    localStorage.getItem("i18nextLng") === "en"
                      ? "Postal code field is empty"
                      : "Postcode veld is leeg"
                  }`
                )
              )
            : isNaN(formData.HouseNumber) || formData.HouseNumber === 0
            ? dispatch(
                setSnackbar(
                  true,
                  "error",
                  `${
                    localStorage.getItem("i18nextLng") === "en"
                      ? "House number field is empty"
                      : "Huisnummer veld is leeg"
                  }`
                )
              )
            : handleOpenModal();
        }}
        className="submit-button"
      >
        {props.data.loading ? (
          <CircularProgress style={{ color: "white" }} />
        ) : (
          t("calculator fields.submit button")
        )}
      </Button>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LcnR8QaAAAAABeub8vW-lFCxrrHRuk1ZNln6JTx"
      />
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return { data: state.calculator };
};
export default connect(mapStateToProps, { sendCalculateInfo, sendCalcEmail })(
  CalculationForm
);
