import React from "react";
import "./Footer.css";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
//@ts-ignore
import pdf from "../../assets/Privacy-Statement-Verkoopgarantie.nu_-1_2.pdf";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-container">
      <div className="footer-elements">
        <div className="footer-elements-item-1">
          <img className="footer-img" src={logo} alt="logo" />
          <div
            className="footer-elements-item-1-divs"
            style={{ marginRight: "40px" }}
          >
            <div className="footer-elements-item-1-city">Rotterdam</div>
            <div className="footer-elements-item-1-infodivs">
              Weena 290, 10e etage
            </div>
            <div className="footer-elements-item-1-infodivs">
              3012 NJ Rotterdam
            </div>
            <div className="footer-elements-item-1-infodivs">
              +31 (0) 646 366 335
            </div>
            <div className="footer-elements-item-1-infodivs">
              info@verkoopgarantie.nu
            </div>
          </div>
        </div>
        <div className="footer-elements-item-1">
          <div className="footer-elements-item-1-divs">
            <div className="footer-elements-item-1-city">Amsterdam</div>
            <div className="footer-elements-item-1-infodivs">
              Keizersgracht 62-64
            </div>
            <div className="footer-elements-item-1-infodivs">
              1015 CS Amsterdam
            </div>
            <div className="footer-elements-item-1-infodivs">
              +31 (0) 646 366 335
            </div>
            <div className="footer-elements-item-1-infodivs">
              info@verkoopgarantie.nu
            </div>
          </div>
          <div className="footer-elements-item-1-divs">
            <div className="footer-elements-item-1-city">
              {t("footer.Navigatie")}
            </div>
            <a href="/" className="navigatie-elements">
              {t("footer.Home")}
            </a>

            <a href="/contact" className="navigatie-elements">
              {t("footer.Contact")}
            </a>
            <a href={pdf} className="navigatie-elements">
              {t("footer.Privacy Statement")}
            </a>
            <a href="/huis-verkopen-amsterdam" className="navigatie-elements">
              {t("footer.Huis verkoopen Amsterdam")}
            </a>
            <a href="/huis-verkopen-rotterdam" className="navigatie-elements">
              {t("footer.Huis verkoopen Rotterdam")}
            </a>
            <a href="/huis-verkopen-den-haag" className="navigatie-elements">
              {t("footer.Huis verkoopen Den Haag")}
            </a>
            <a href="/huis-verkopen-utrecht" className="navigatie-elements">
              {t("footer.Huis verkoopen Utrecht")}
            </a>
            <a href="/huis-verkopen-eindhoven" className="navigatie-elements">
              {t("footer.Huis verkoopen Eindhoven")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
