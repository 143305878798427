import React from "react";
import { useTranslation } from "react-i18next";
import MyAccordion from "../../../Accordion/MyAccordion";
import "./AcordionsComponent.css";
const AcordionsComponent = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background:
          " transparent linear-gradient(180deg, #FFFFFF 0%, #E9EFFC 100%, #E8EEFC 100%) 0% 0% no-repeat padding-box",
      }}
    >
      <h2 className="header-accordions-segment"> {t("accordion.header")}</h2>
      <div className="description-accordions-segment">
        {t("accordion.description")}
      </div>
      <MyAccordion
        isDefaultOpen
        title={t("accordion.items.1.header")}
        description={t("accordion.items.1.description")}
      />
      <MyAccordion
        title={t("accordion.items.2.header")}
        description={t("accordion.items.2.description")}
      />
      <MyAccordion
        title={t("accordion.items.3.header")}
        description={t("accordion.items.3.description")}
      />
      <MyAccordion
        title={t("accordion.items.4.header")}
        description={t("accordion.items.4.description")}
      />
    </div>
  );
};

export default AcordionsComponent;
