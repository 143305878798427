import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import i18next from "i18next";
import { Nl, Gb } from "react-flags-select";
import React, { useEffect } from "react";

const Languageselect = (props: { color: string }) => {
  useEffect(() => {
    i18next.changeLanguage(localStorage.getItem("i18nextLng") || "nl");
    setlanguage(localStorage.getItem("i18nextLng") || "nl");
  }, []);
  function switchLanguage(lang: string) {
    localStorage.setItem("hasChangedLanguage", "1");
    i18next.changeLanguage(lang);
  }
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      root: {
        "& .MuiInputLabel-shrink": {
          transform_origin: " top right",
        },
        "& .MuiInputBase-input": {
          height: "30px",
          color: `${props.color}`,
          display: "flex",
          flexDirextion: "row",
          alignItems: "center",
        },
        "& .MuiInputLabel-root": {
          "transform-origin": "top right",
        },
        "& .MuiFormLabel-root": {
          color: `${props.color}`,
          transition: ".3s",
          fontSize: "15px",
        },
      },
      icon: {
        fill: `${props.color}`,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const classes = useStyles();
  const [language, setlanguage] = React.useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setlanguage(event.target.value as string);
    switchLanguage(event.target.value as string);
  };
  return (
    <div style={{ marginBottom: "10px" }}>
      <FormControl className={classes.root}>
        <Select
          disableUnderline={true}
          classes={{
            icon: classes.icon,
          }}
          MenuProps={{
            disableScrollLock: true,
          }}
          value={language}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={"nl"}>
            <Nl className="selectlanguage-icon"></Nl>
            <div className="selectlanguage-countryname">NL</div>
          </MenuItem>
          <MenuItem value={"en"}>
            <Gb className="selectlanguage-icon"></Gb>
            <div className="selectlanguage-countryname">EN</div>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Languageselect;
