import { useContext } from "react";
import "./MapAndInfo.css";
import building from "./icons/building.svg";
import label from "./icons/material-label.svg";
import calendar from "./icons/calendar.svg";
import square from "./icons/square.svg";
import area from "./icons/area.svg";
import Map from "./map/Map";
import { useTranslation } from "react-i18next";
import CalculatorContext from "../CalculatorContext";
import { calc_redux_Type } from "../../../../redux/reducer/calculator";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
const Skeleton = require("@material-ui/lab/Skeleton");
const MapAndInfo = (props: any) => {
  const { t } = useTranslation();
  const data: calc_redux_Type = useContext(CalculatorContext);
  return (
    <div className="map-and-info-container">
      <div className="mai-info-div">
        <div className="mai-header">
          {data.loading ? (
            <Typography component="div" key={"h3"} variant={"h3"}>
              {
                <Skeleton
                  style={{ backgroundColor: "#3B3B3B", width: "100%" }}
                />
              }
            </Typography>
          ) : (
            data.calcInfo.city
          )}{" "}
          {data.calcInfo.street} {data.calcInfo.houseNumber}
        </div>
        <div className="mai-info-items-container">
          <div className="mai-info-single-item-container">
            <img src={building} alt="" />
            <div className="mai-item-info">
              <div className="mai-item-info-title">
                {t("calc_page.info_items.1")}
              </div>
              <div>
                {data.loading ? (
                  <Typography
                    component="div"
                    key={"caption"}
                    variant={"caption"}
                  >
                    {
                      <Skeleton
                        style={{ backgroundColor: "#3B3B3B", width: "40px" }}
                      />
                    }
                  </Typography>
                ) : data.calcInfo.houseType ? (
                  data.calcInfo.houseType
                ) : (
                  sessionStorage.getItem("houseType")
                )}
              </div>
            </div>
          </div>
          <div className="mai-info-single-item-container">
            <img src={calendar} alt="" />
            <div className="mai-item-info">
              <div className="mai-item-info-title">
                {t("calc_page.info_items.2")}
              </div>
              <div>
                {data.loading ? (
                  <Typography
                    component="div"
                    key={"caption"}
                    variant={"caption"}
                  >
                    {
                      <Skeleton
                        style={{ backgroundColor: "#3B3B3B", width: "40px" }}
                      />
                    }
                  </Typography>
                ) : data.calcInfo.buildYear ? (
                  data.calcInfo.buildYear
                ) : (
                  sessionStorage.getItem("buildYear")
                )}
              </div>
            </div>
          </div>
          <div className="mai-info-single-item-container">
            <img src={label} alt="" />
            <div className="mai-item-info">
              <div className="mai-item-info-title">
                {t("calc_page.info_items.3")}
              </div>
              <div>
                {data.loading ? (
                  <Typography
                    component="div"
                    key={"caption"}
                    variant={"caption"}
                  >
                    {
                      <Skeleton
                        style={{ backgroundColor: "#3B3B3B", width: "40px" }}
                      />
                    }
                  </Typography>
                ) : data.calcInfo.energyLabel ? (
                  data.calcInfo.energyLabel
                ) : (
                  sessionStorage.getItem("energyLabel")
                )}
              </div>
            </div>
          </div>
          <div className="mai-info-single-item-container">
            <img src={square} alt="" />
            <div className="mai-item-info">
              <div className="mai-item-info-title">
                {t("calc_page.info_items.4")}
              </div>
              <div>
                {data.loading ? (
                  <Typography
                    component="div"
                    key={"caption"}
                    variant={"caption"}
                  >
                    {
                      <Skeleton
                        style={{ backgroundColor: "#3B3B3B", width: "40px" }}
                      />
                    }
                  </Typography>
                ) : data.calcInfo.innerSurfaceArea ? (
                  data.calcInfo.innerSurfaceArea
                ) : (
                  sessionStorage.getItem("innerSurfaceArea")
                )}
                m2
              </div>
            </div>
          </div>
          <div className="mai-info-single-item-container">
            <img src={area} alt="" />
            <div className="mai-item-info">
              <div className="mai-item-info-title">
                {t("calc_page.info_items.5")}
              </div>
              <div>
                {data.loading ? (
                  <Typography
                    component="div"
                    key={"caption"}
                    variant={"caption"}
                  >
                    {
                      <Skeleton
                        style={{ backgroundColor: "#3B3B3B", width: "40px" }}
                      />
                    }
                  </Typography>
                ) : data.calcInfo.outerSurfaceArea ? (
                  data.calcInfo.outerSurfaceArea
                ) : (
                  sessionStorage.getItem("outerSurfaceArea")
                )}
                m2
              </div>
            </div>
          </div>
        </div>
        <Map
          lat={
            data.calcInfo.latitude
              ? Number(data.calcInfo.latitude)
              : Number(sessionStorage.getItem("latitude") || "")
          }
          long={
            data.calcInfo.longitude
              ? Number(data.calcInfo.longitude)
              : Number(sessionStorage.getItem("longitude") || "")
          }
        />
      </div>
    </div>
  );
};
const mapStatetoProps = (state: any) => {
  return { data: state.calculator };
};
export default connect(mapStatetoProps)(MapAndInfo);
