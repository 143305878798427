import React from "react";
import { Button } from "@material-ui/core";
import ErrorIcon from "../../../../assets/images/error-icon.png";
import "./CustomError.css";

const Error = ({ onClick, text }) => {
  return (
    <div className="custom-error">
      <div>
        <img src={ErrorIcon} alt="" />
      </div>
      <h1>
        {localStorage.getItem("i18nextLng") === "en"
          ? "An error has occurred"
          : "Er is een fout opgetreden"}
      </h1>
      <p>{text}</p>
      <div>
        <Button variant="contained" onClick={onClick}>
          {localStorage.getItem("i18nextLng") === "en" ? "Close" : "Sluiten"}
        </Button>
      </div>
    </div>
  );
};
export default Error;
