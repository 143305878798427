import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';


const CustomCheckBox=()=>{
return(
    <div className="check-box-final">
            <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  checked={true}
                  
            />
    </div>
        
)
}
export default CustomCheckBox;