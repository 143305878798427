import React from "react";
import "../Amesterdam/Amsterdam.css";
import "./Waroom.css";

import { Check, Close } from "@material-ui/icons";
import CalculatorAdvan from "../calculator/calculator_advan/CalculatorAdvan";
import bg from "../../../assets/backgrounds/background.png";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import history from "../../../dependencies/history/history";
const Waroom = () => {
  const { t } = useTranslation();
  return (
    <div className="amsterdam-container">
      <head>
        <title>Waarom? - Verkoopgarantie</title>
        <meta
          name="description"
          content="Bekijk hier alle voordelen van een verkoop via verkoopgarantie.nu."
        />
      </head>
      <div className="cities-video-container">
        <img className="cities-video" src={bg} alt=""></img>
        <div className="cities-video-div">
          <h1 className="cities-video-header" style={{ maxWidth: "539px" }}>
            {t("Waarom.header")}
          </h1>
          <div className="contact-description">{t("Waarom.description")}</div>
        </div>
      </div>
      <div className="waroom-items-container">
        <div className="waroom-items-div">
          <div className="waroom-items">
            <div className="waroom-item-title">
              {t("Waarom.item.header.verkoop")}
            </div>
            <img
              className="waroom-imgs"
              src="https://www.verkoopgarantie.nu/wp-content/uploads/2020/08/Verkoopgarantie.nu-kleurkl.png"
              alt="verkoopgarantie.nu"
              title="Verkoopgarantie.nu-kleurkl"
            />
            <div className="div red-container">
              {t("Waarom.item.sellDuration.verkoop")}
            </div>
            <div className="waroom-devider" />
            <div className="warooom-items-options-container">
              <div className="warooom-items-options-div">
                <Close className="warooom-items-options-icon-false"></Close>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.1")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Close className="warooom-items-options-icon-false"></Close>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.2")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Close className="warooom-items-options-icon-false"></Close>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.3")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Close className="warooom-items-options-icon-false"></Close>
                <div className="warooom-items-options-text">
                  {" "}
                  {t("Waarom.item.options.4")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Close className="warooom-items-options-icon-false"></Close>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.5")}
                </div>
              </div>
              <div className="waroom-price-div">
                <div className="waroom-price-title">
                  {" "}
                  {t("Waarom.item.options.6")}
                </div>
                <div className="waroom-price-price">€ 0,00</div>
              </div>
              <div className="waroom-foot-text">
                {t("Waarom.item.options.7")}
              </div>
            </div>
          </div>
          <div className="waroom-items">
            <div className="waroom-item-title">
              {t("Waarom.item.header.funda")}
            </div>
            <img
              src="https://www.verkoopgarantie.nu/wp-content/uploads/2020/08/download@2x2.png"
              alt=""
              title="download@2x"
            />
            <div className="div red-container">
              {t("Waarom.item.sellDuration.funda")}
            </div>
            <div className="waroom-devider" />
            <div className="warooom-items-options-container">
              <div className="warooom-items-options-div">
                <Check className="warooom-items-options-icon-true"></Check>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.1")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Check className="warooom-items-options-icon-true"></Check>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.2")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Check className="warooom-items-options-icon-true"></Check>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.3")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Check className="warooom-items-options-icon-true"></Check>
                <div className="warooom-items-options-text">
                  {" "}
                  {t("Waarom.item.options.4")}
                </div>
              </div>
              <div className="warooom-items-options-div">
                <Check className="warooom-items-options-icon-true"></Check>
                <div className="warooom-items-options-text">
                  {t("Waarom.item.options.5")}
                </div>
              </div>
            </div>
            <div className="waroom-price-div">
              <div className="waroom-price-title">
                {t("Waarom.item.options.6")}
              </div>
              <div className="waroom-price-price">€ 6.000,- / 10.000,-</div>
            </div>
            <div className="waroom-foot-text">{t("Waarom.item.options.7")}</div>
          </div>
        </div>
        <Button
          style={{ marginTop: "60px", marginBottom: "10px" }}
          onClick={() => {
            history.push("/");
            window.scrollTo({ top: 0 });
          }}
          className="submit-button"
        >
          {t("waroom button")}
        </Button>
      </div>

      <div style={{ width: "100%" }}>
        <CalculatorAdvan />
      </div>
    </div>
  );
};

export default Waroom;
