import React, { FC, useState } from "react";

import "../Amesterdam/Amsterdam.css";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import { Button, OutlinedInput } from "@material-ui/core";
import "../calculator/price_and_forms/PriceAndForm.css";
import { connect, useDispatch } from "react-redux";
import bg from "../../../assets/backgrounds/background.png";
import {
  sendEmail,
  ContactFormTypes,
} from "../../../redux/actions/Contact_actions";
import { setSnackbar } from "../../../redux/actions/snackbar";
interface Props {
  sendEmail: (contactData: ContactFormTypes) => void;
}

const Contact: FC<Props> = ({ sendEmail }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [family, setFamilyName] = useState("");
  const [email, setEmailAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [massage, setMassage] = useState("");
  const handleSubmit = (data: ContactFormTypes) => {
    sendEmail(data);
    setName("");
    setFamilyName("");
    setEmailAddress("");
    setPhone("");
    setMassage("");
  };
  return (
    <div className="amsterdam-container">
      <head>
        <title>Contact – Verkoopgarantie Contactgegevens</title>
        <meta
          name="description"
          content="Huis snel verkopen? Neem nu vrijblijvend contact op voor de snelste verkoop tegen de scherpste prijzen."
        />
      </head>
      <div className="cities-video-container">
        <img className="cities-video" src={bg} alt=""></img>
        <div className="cities-video-div">
          <h1 className="cities-video-header">CONTACT</h1>
          <div className="contact-description">{t("Contact.description")}</div>
        </div>
      </div>
      <div className="contact-forms-and-d-container">
        <div className="contact-forms-and-d-div">
          <div className="contact-forms-continer">
            <div className="paf-forms-1-div">
              <OutlinedInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  border: "2px solid #DCE2EE",
                  marginTop: "15px",
                }}
                className="paf-1-field contact-form"
                placeholder={t("Contact.form.1")}
                autoComplete="off"
              />
              <OutlinedInput
                value={family}
                onChange={(e) => setFamilyName(e.target.value)}
                style={{
                  border: "2px solid #DCE2EE",
                  marginTop: "15px",
                  marginRight: "0",
                }}
                className="paf-1-field contact-form"
                placeholder={t("Contact.form.2")}
                autoComplete="off"
              />
            </div>
            <div className="paf-forms-1-div">
              <OutlinedInput
                value={email}
                onChange={(e) => setEmailAddress(e.target.value)}
                style={{
                  border: "2px solid #DCE2EE",
                  marginTop: "15px",
                }}
                className="paf-1-field contact-form"
                placeholder={t("Contact.form.3")}
                autoComplete="off"
              />
              <OutlinedInput
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{
                  border: "2px solid #DCE2EE",
                  marginTop: "15px",
                  marginRight: "0",
                }}
                className="paf-1-field contact-form"
                placeholder={t("Contact.form.4")}
                autoComplete="off"
              />
            </div>
            <OutlinedInput
              value={massage}
              onChange={(e) => setMassage(e.target.value)}
              style={{
                border: "2px solid #DCE2EE",
                marginRight: "0",
                marginTop: "15px",
              }}
              multiline
              rowsMax={4}
              className="paf-1-field paf-2-field"
              placeholder={t("Contact.form.5")}
              autoComplete="off"
            />
            <div
              style={{
                fontSize: "14px",
                color: "#666",
                width: "100%",
                marginTop: "60px",
              }}
            >
              {t("calc_page.privacy_description")}{" "}
              <a
                className="privacy-anchor"
                href="https://www.verkoopgarantie.nu/wp-content/uploads/2020/08/Privacy-Statement-Verkoopgarantie.nu_-1.pdf"
              >
                {" "}
                {t("calc_page.privacy_link_text")}
              </a>
              .
            </div>
            <Button
              onClick={() => {
                if (
                  email === "" ||
                  name === "" ||
                  family === "" ||
                  phone === ""
                ) {
                  dispatch(
                    setSnackbar(
                      true,
                      "error",
                      `${
                        localStorage.getItem("i18nextLng") === "en"
                          ? "Please fill out all the fields"
                          : "Gelieve alle velden in te vullen"
                      }`
                    )
                  );
                } else
                  handleSubmit({
                    EmailAddress: email,
                    FirstName: name,
                    LastName: family,
                    PhoneNumber: phone,
                    Message: massage,
                  });
              }}
              style={{ marginTop: "30px" }}
              className="privacy-button"
              variant="contained"
            >
              {t("Contact.button")}
            </Button>
          </div>
          <div className="contact-helper-div-container">
            <div
              style={{ color: "white", fontWeight: "bold", fontSize: "29px" }}
            >
              Verkoopgarantie.nu
            </div>
            <div className="contact-helper-div-city">ROTTERDAM:</div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              Weena 290, 10e etage
            </div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              3012 NJ Rotterdam
            </div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              +31 (0) 646 366 335
            </div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              info@verkoopgarantie.nu
            </div>
            <div className="contact-helper-div-city">AMSTERDAM:</div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              Keizersgracht 62-64
            </div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              1015 CS Amsterdam
            </div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              +31 (0) 646 366 335
            </div>
            <div style={{ marginBottom: "5px", color: "white" }}>
              info@verkoopgarantie.nu
            </div>
            <div className="contact-helper-div-opentime">
              {t("Contact.open_time")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { sendEmail })(Contact);
