export const GET_CALCULATE = "GET_CALCULATE";
export const LOADING_CALCULATE = "LOADING_CALCULATE";
export const LOADING_END_CALCULATE = "LOADING_END_CALCULATE";
//
export const SEND_INFO_CONTACT = "SEND_INFO_CONTACT";
export const LOADING_CONTACT = "LOADING_CONTACT";
export const LOADING_END_CONTACT = "LOADING_END_CONTACT";
//
export const SET_SNACKBAR = "SET_SNACKBAR";
//
export const CALC_EMAIL_LOADING="CALC_EMAIL_LOADING";
export const CALC_EMAIL_LOADING_END="CALC_EMAIL_LOADING_END";
