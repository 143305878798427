import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n.ts";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./redux/reducer";

const store = createStore(reducers, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Suspense fallback={<div></div>}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
