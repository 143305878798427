import React from "react";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import Languageselect from "../nav/Languageselect";
import history from "../../dependencies/history/history";
export default function Drawer() {
  const { t } = useTranslation();

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <List style={{ width: "240px" }}>
      <ListItem
        button
        onClick={() => {
          setState({ right: false });
          history.push("/");
          window.scrollTo({ top: 0 });
        }}
      >
        <ListItemText>{t("navbar.Home")}</ListItemText>
      </ListItem>

      <ListItem
        button
        onClick={() => {
          setState({ right: false });
          history.push("/waroom");
          window.scrollTo({ top: 0 });
        }}
      >
        <ListItemText>{t("navbar.Waroom")}?</ListItemText>
      </ListItem>
      <ListItem
        button
        onClick={() => {
          setState({ right: false });
          history.push("/contact");
          window.scrollTo({ top: 0 });
        }}
      >
        <ListItemText>{t("navbar.Contact")}</ListItemText>
      </ListItem>
      <ListItem>
        <Languageselect color="#0A0C12" />
      </ListItem>
    </List>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button onClick={toggleDrawer("right", true)}>
          <MenuIcon style={{ color: "white" }} />
        </Button>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
