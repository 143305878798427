import React from "react";

import city_house from "../../../assets/images/cities_img/city_house.svg";
import city_girl from "../../../assets/images/cities_img/city_girl.svg";
import "../calculator/calculator_advan/CalculatorAdvan.css";
import check from "../../../assets/images/check.svg";
import "../Amesterdam/Amsterdam.css";
import "./Eindhoven.css";
import "../home/home components/SecAdvertise.css";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MyAccordion from "../../Accordion/MyAccordion";
import history from "../../../dependencies/history/history";
import bg from "../../../assets/backgrounds/background.png";
const Eindhoven = () => {
  const { t } = useTranslation();
  return (
    <div className="amsterdam-container">
      <head>
        <title>Huis verkopen Eindhoven | Beste prijsgarantie 2021</title>
        <meta
          name="description"
          content="Huis snel verkopen in Eindhoven? Dan bent u hier aan het juiste adres. Verkoopgarantie.nu is het snelste verkoopkanaal voor woningen in Eindhoven."
        />
      </head>
      <div className="cities-video-container">
        <img className="cities-video" src={bg} alt=""></img>
        <div className="cities-video-div">
          <h1 className="cities-video-header">{t("eindhoven.header")}</h1>
        </div>
      </div>
      <div className="amsterdam-div">
        <div className="amsterdam-item-1-container">
          <div className="amsterdam-item-1-div">
            <div className="amsterdam-header">{t("eindhoven.1.header")}</div>
            <div className="amsterdam-description">
              {t("eindhoven.1.description")}
            </div>
          </div>
          <img className="house-and-girl-img" src={city_house} alt="" />
        </div>
        <div className="amsterdam-item-2-container">
          <img className="house-and-girl-img" src={city_girl} alt="" />
          <div className="amsterdam-item-1-div">
            <div className="amsterdam-header">{t("eindhoven.2.header")}</div>
            <div className="amsterdam-description">
              {t("eindhoven.2.description")}
            </div>
            <div className="calculator-advan-options-div">
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.1")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.2")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.3")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.4")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.5")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.6")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("eindhoven.2.options.7")}
                </div>
              </div>

              <Button
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  history.push("/");
                }}
                className="ad-2-button"
                variant="contained"
              >
                {t("ad-2.button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="eindhoven-accordion-container">
        <div className="eindhoven-accordion-header">
          {t("eindhoven.ind_header")}
        </div>
        <div className="eindhoven-accordion-description">
          {t("eindhoven.ind_description")}
        </div>
        <MyAccordion
          title={t("eindhoven.accordion.1.header")}
          description={t("eindhoven.accordion.1.description")}
          isDefaultOpen
        />
        <MyAccordion
          title={t("eindhoven.accordion.2.header")}
          description={t("eindhoven.accordion.2.description")}
        />
        <MyAccordion
          title={t("eindhoven.accordion.3.header")}
          description={t("eindhoven.accordion.3.description")}
        />
      </div>
    </div>
  );
};

export default Eindhoven;
