import React from "react";
import "./CalculatorAdvan.css";
import check from "../../../../assets/images/check.svg";
import { useTranslation } from "react-i18next";
const CalculatorAdvan = () => {
  const { t } = useTranslation();
  return (
    <div className="calculator-advan-container">
      <div className="calculator-advan-header">
        {t("calc_page.calc_options.header")}
      </div>
      <div className="calculator-advan-options-container">
        <div className="calculator-advan-options-div">
          <div className="calculator-advan-options-header">
            {t("calc_page.calc_options.1.header")}
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.1.1")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.1.2")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.1.3")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.1.4")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.1.5")}
            </div>
          </div>
        </div>
        <div className="calculator-advan-options-div">
          <div className="calculator-advan-options-header">
            {t("calc_page.calc_options.2.header")}
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.2.1")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.2.2")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.2.3")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.2.4")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.2.5")}
            </div>
          </div>
        </div>
        <div className="calculator-advan-options-div">
          <div className="calculator-advan-options-header">
            {t("calc_page.calc_options.3.header")}
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.3.1")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.3.2")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.3.3")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.3.4")}
            </div>
          </div>
          <div className="ca-options-div">
            <img className="ca-check-img" src={check} alt="" />
            <div className="ca-options-description">
              {t("calc_page.calc_options.3.5")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorAdvan;
