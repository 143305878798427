import React from "react";
import { useTranslation } from "react-i18next";
import "./AdvantagesComponent.css";
import "./ContactUs.css";
import girl from "../../../../assets/images/girl.jpg";
import boy from "../../../../assets/images/boy.png";
const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className="advantages-segment-div">
      <h2 className="contact-us-header">{t("contact us.header")}</h2>
      <div className="contact-us-description">
        {t("contact us.description")}
      </div>
      <div className="specialists-container">
        <div className="specialists-imgAndDiv">
          <img className="specialist-image" alt="" src={girl}></img>
          <div className="specialists-div">
            <div className="specialist-name">Halle</div>
            <div className="specialist-info">{t("specialist.girl")}</div>
            <div className="specialist-info">Rotterdam</div>
            <div className="specialist-info">06-46366335</div>
          </div>
        </div>
        <div className="specialists-imgAndDiv">
          <img className="specialist-image" alt="" src={boy}></img>
          <div className="specialists-div">
            <div className="specialist-name">Michel</div>
            <div className="specialist-info">{t("specialist.boy")}</div>
            <div className="specialist-info">Amsterdam</div>
            <div className="specialist-info">06-46366335</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
