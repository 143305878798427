import React, { useEffect, useState } from "react";
import NavBar from "../nav/NavBar";
import "./Header.css";
import logo from "../../assets/images/logo.png";
import Drawer from "../Drawer/Drawer";
import history from "../../dependencies/history/history";
const Header = () => {
  const [headerScroll, setheaderScroll] = useState("");
  const [imageScroll, setimageScroll] = useState("");
  const listenScrollEvent = () => {
    if (window.scrollY > 100) {
      setheaderScroll("header-scroll");
      setimageScroll("image-scroll");
    } else {
      setimageScroll("");
      setheaderScroll("");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });
  return (
    <div className={`header-component ${headerScroll}`}>
      <div className="button-logo-container">
        <img
          onClick={() => {
            history.push("/");
          }}
          className={`logo-img ${imageScroll}`}
          src={logo}
          alt="logo"
        />
        <NavBar />
        <div className="mobile-drower-container">
          <Drawer></Drawer>
        </div>
      </div>
    </div>
  );
};

export default Header;
