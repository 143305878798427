import React from "react";
import house1 from "../../../../assets/images/house1.png";
import house2 from "../../../../assets/images/house2.png";
import house3 from "../../../../assets/images/house3.png";
import house4 from "../../../../assets/images/house4.png";
import house5 from "../../../../assets/images/house5.png";
import house6 from "../../../../assets/images/house6.png";
import house7 from "../../../../assets/images/house7.png";
import house8 from "../../../../assets/images/house8.png";
import ad1 from "../../../../assets/images/calculateb1.png";
import { useTranslation } from "react-i18next";
import "./RecentSells.css";
import { Button } from "@material-ui/core";
const RecentSells = () => {
  const { t } = useTranslation();
  return (
    <div className="recents-container">
      <h2 className="recents-header">{t("recents.header")}</h2>
      <div className="recents-description">{t("recents.description")}</div>
      <div className="recents-item-container">
        <div className="recents-item">
          <img src={house1} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Marthinus Steynstraat, 33 & 35, Dordrecht<br />9 kamer appartement
          </div>
        </div>
        <div className="recents-item">
          <img src={house2} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Repelvoorde 5, Spijkenisse<br />5 Kamer tussenwoning
          </div>
        </div>
        <div className="advertise-1-container-desk-big">
          <img src={ad1} width="637" height="364" alt="" />
          <div className="ad-1-cover">
            <div className="cover-header">{t("recents.ad-1")}</div>
            <div>
              {" "}
              <Button
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                style={{ backgroundColor: "#FA7F28", marginTop: "40px" }}
                variant="contained"
                color="primary"
              >
                {t("recents.button")}
              </Button>
            </div>
          </div>
        </div>
        <div className="recents-item">
          <img src={house3} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Burgemeester Knappertlaan 185 A, Schiedam<br />6 Kamer appartement
          </div>
        </div>

        <div className="recents-item">
          <img src={house4} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Jacob van Wassenaerstraat, 38, Helmond<br />4 Kamer tussenwoning
          </div>
        </div>
        <div className="advertise-1-container-desk-small">
          <img src={ad1} className="ad-img1" alt="" />
          <div className="ad-1-cover">
            <div className="cover-header">{t("recents.ad-1")}</div>
            <Button
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              style={{ backgroundColor: "#FA7F28", marginTop: "40px" }}
              variant="contained"
              color="primary"
            >
              {t("recents.button")}
            </Button>
          </div>
        </div>
        <div className="recents-item">
          <img src={house5} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Schiedamseweg Beneden 459-03L, Rotterdam<br />2 Kamer appartement
          </div>
        </div>
        <div className="recents-item">
          <img src={house6} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Jozef Oreliosingel 281, Schiedam<br />4 Kamer tussenwoning
          </div>
        </div>
        <div className="recents-item">
          <img src={house7} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Wislaan 76, Uden<br />4 Kamer vrijstaand geschakeld
          </div>
        </div>
        <div className="recents-item">
          <img src={house8} alt="" className="houses-img" />
          <div className="house-status">{t("recents.house status")}</div>
          <div className="house-description">
            Max Havelaarweg 178, Hoogvliet,<br />4 Kamer tussenwoning
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentSells;
