import React from "react";
import { useTranslation } from "react-i18next";
import { backGround_video } from "../../../utils/Urls";
import  backGVideo  from "../../../assets/videos/backGVideo.mp4";
import Carousel from "../../Carousel/Carousel";

import AcordionsComponent from "./home components/AcordionsComponent";
import AdvantagesComponent from "./home components/AdvantagesComponent";
import BadState from "./home components/BadState";
import CalculationForm from "./home components/CalculationForm";
import ContactUs from "./home components/ContactUs";
import FlexibleSegment from "./home components/FlexibleSegment";
import RecentSells from "./home components/RecentSells";
import SecAdvertise from "./home components/SecAdvertise";
import "./HomePage.css";
const HomePage = () => {
  const { t } = useTranslation();
  return (
    <div className="home-div">
      <head>
        <title>
          Huis snel verkopen? Verkoopgarantie.nu | Best beoordeeld 2021
        </title>
        <meta
          name="description"
          content="Huis snel verkopen? Verkoopgarantie.nu garandeert een verkoop binnen 1 week. Nederlands snelste verkoopkanaal. Als best beoordeeld door klanten 2020."
        />
      </head>
      <div className="video-and-calculator-div">
        <video className="video-tag" playsInline loop autoPlay muted>
          <source src={backGVideo} type="video/mp4" />
          <source src={backGVideo} type="video/webm" />
          <source src={backGVideo} type="video/ogg" />
          <source src={backGVideo} type="video/mp4" />
        </video>
        <div className="home-calculate-div">
          <h1 className="calculator-text-head">
            {t("calculator home.header")}
          </h1>
          <div className="calculator-text-description">
            {t("calculator home.description")}
          </div>
          <CalculationForm />
        </div>
      </div>{" "}
      <AdvantagesComponent />
      <ContactUs />
      <RecentSells />
      <SecAdvertise />
      <AcordionsComponent />
      <BadState />
      <FlexibleSegment />
      <Carousel />
    </div>
  );
};

export default HomePage;
