import React from "react";
import city_house from "../../../assets/images/cities_img/city_house.svg";
import city_girl from "../../../assets/images/cities_img/city_girl.svg";
import "../calculator/calculator_advan/CalculatorAdvan.css";
import check from "../../../assets/images/check.svg";
import "./Amsterdam.css";
import "../home/home components/SecAdvertise.css";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import history from "../../../dependencies/history/history";
import bg from "../../../assets/backgrounds/background.png";
const Amsterdam = () => {
  const { t } = useTranslation();
  return (
    <div className="amsterdam-container">
      <head>
        <title>Huis verkopen Amsterdam | Beste prijsgarantie 2021</title>
        <meta
          name="description"
          content="Huis snel verkopen in Amsterdam? Dan bent u hier aan het juiste adres. Verkoopgarantie.nu is het snelste verkoopkanaal voor woningen in Amsterdam."
        />
      </head>
      <div className="cities-video-container">
        <img className="cities-video" src={bg}></img>
        <div className="cities-video-div">
          <h1 className="cities-video-header">{t("amsterdam.header")}</h1>
        </div>
      </div>
      <div className="amsterdam-div">
        <div className="amsterdam-item-1-container">
          <div className="amsterdam-item-1-div">
            <div className="amsterdam-header">{t("amsterdam.1.header")}</div>
            <div className="amsterdam-description">
              {t("amsterdam.1.description")}
            </div>
          </div>
          <img className="house-and-girl-img" src={city_house} alt="" />
        </div>
        <div className="amsterdam-item-2-container">
          <img className="house-and-girl-img" src={city_girl} alt="" />
          <div className="amsterdam-item-1-div">
            <div className="amsterdam-header">{t("amsterdam.2.header")}</div>
            <div className="amsterdam-description">
              {t("amsterdam.2.description")}
            </div>
            <div className="calculator-advan-options-div">
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("amsterdam.2.options.1")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("amsterdam.2.options.2")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("amsterdam.2.options.3")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("amsterdam.2.options.4")}
                </div>
              </div>
              <div className="ca-options-div">
                <img
                  className="ca-check-img"
                  style={{ margin: "0" }}
                  src={check}
                  alt=""
                />
                <div
                  className="ca-options-description "
                  style={{ fontSize: "22px" }}
                >
                  {t("amsterdam.2.options.5")}
                </div>
              </div>
              <Button
                onClick={() => {
                  window.scrollTo({ top: 0 });
                  history.push("/");
                }}
                className="ad-2-button"
                variant="contained"
              >
                {t("ad-2.button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Amsterdam;
