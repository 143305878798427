import React, { useEffect } from "react";
import "./NavBar.css";

import { useTranslation } from "react-i18next";
import Languageselect from "./Languageselect";
import history from "../../dependencies/history/history";
const NavBar = () => {
  const { t } = useTranslation();
  const [homeSelected, selectHome] = React.useState(false);
  const [waroomSelected, selectWaroom] = React.useState(false);
  const [contactSelected, selectContact] = React.useState(false);
  useEffect(() => {
    history.listen(() => {
      if (history.location.pathname.includes("/")) {
        selectHome(true);
        selectWaroom(false);
        selectContact(false);
      }
      if (history.location.pathname.includes("/waroom")) {
        selectWaroom(true);
        selectHome(false);
        selectContact(false);
      }
      if (history.location.pathname.includes("/contact")) {
        selectContact(true);
        selectHome(false);
        selectWaroom(false);
      }
    });
    if (history.location.pathname.includes("/")) {
      selectHome(true);
      selectWaroom(false);
      selectContact(false);
    }
    if (history.location.pathname.includes("/waroom")) {
      selectWaroom(true);
      selectHome(false);
      selectContact(false);
    }
    if (history.location.pathname.includes("/contact")) {
      selectContact(true);
      selectHome(false);
      selectWaroom(false);
    }
  }, []);

  return (
    <div className="navbar-container">
      <div
        onClick={() => {
          history.push("/");
          window.scrollTo({ top: 0 });
        }}
        className={`navbar-item ${homeSelected ? " navbar-item-selected" : ""}`}
      >
        {t("navbar.Home")}
      </div>
      <div
        onClick={() => {
          history.push("/waroom");
          window.scrollTo({ top: 0 });
        }}
        className={`navbar-item ${
          waroomSelected ? " navbar-item-selected" : ""
        }`}
      >
        {t("navbar.Waroom")}?
      </div>
      <div
        onClick={() => {
          history.push("/contact");
          window.scrollTo({ top: 0 });
        }}
        className={`navbar-item ${
          contactSelected ? " navbar-item-selected" : ""
        }`}
      >
        {t("navbar.Contact")}
      </div>

      <Languageselect color="#fff" />
    </div>
  );
};

export default NavBar;
