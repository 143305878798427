import React from "react";
import "./AdvantagesComponent.css";
import { useTranslation } from "react-i18next";
const AdvantagesComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="advantages-segment-div">
      <h2 className="advantages-segment-text-head">{t("advantages.header")}</h2>
      <div className="advantages-segment-text-description">
        {t("advantages.description")}
      </div>
      <div className="advantages-options-container">
        <div className="advantages-options-div">
          <div className="advantages-options-icon-div">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="120"
              viewBox="0 0 120 120"
            >
              <g
                id="Step_1"
                data-name="Step 1"
                transform="translate(-417 -1548)"
              >
                <path
                  id="Oval"
                  d="M60,0A60,60,0,1,1,0,60,60,60,0,0,1,60,0Z"
                  transform="translate(417 1548)"
                  fill="#fa7f28"
                />
                <path
                  id="Icon_awesome-calculator"
                  data-name="Icon awesome-calculator"
                  d="M38.012,0H4.561A4.677,4.677,0,0,0,0,4.561V44.094a4.677,4.677,0,0,0,4.561,4.561H38.012a4.677,4.677,0,0,0,4.561-4.561V4.561A4.677,4.677,0,0,0,38.012,0ZM12.164,41.357a1.308,1.308,0,0,1-1.216,1.216H7.3a1.308,1.308,0,0,1-1.216-1.216V37.708A1.308,1.308,0,0,1,7.3,36.491h3.649a1.308,1.308,0,0,1,1.216,1.216Zm0-12.164a1.308,1.308,0,0,1-1.216,1.216H7.3a1.308,1.308,0,0,1-1.216-1.216V25.544A1.308,1.308,0,0,1,7.3,24.328h3.649a1.308,1.308,0,0,1,1.216,1.216ZM24.328,41.357a1.308,1.308,0,0,1-1.216,1.216H19.462a1.308,1.308,0,0,1-1.216-1.216V37.708a1.308,1.308,0,0,1,1.216-1.216h3.649a1.308,1.308,0,0,1,1.216,1.216Zm0-12.164a1.308,1.308,0,0,1-1.216,1.216H19.462a1.308,1.308,0,0,1-1.216-1.216V25.544a1.308,1.308,0,0,1,1.216-1.216h3.649a1.308,1.308,0,0,1,1.216,1.216ZM36.491,41.357a1.308,1.308,0,0,1-1.216,1.216H31.626a1.308,1.308,0,0,1-1.216-1.216V25.544a1.308,1.308,0,0,1,1.216-1.216h3.649a1.308,1.308,0,0,1,1.216,1.216V41.357Zm0-24.328a1.308,1.308,0,0,1-1.216,1.216H7.3a1.308,1.308,0,0,1-1.216-1.216V7.3A1.308,1.308,0,0,1,7.3,6.082H35.275A1.308,1.308,0,0,1,36.491,7.3Z"
                  transform="translate(455.713 1583.673)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <div className="advantages-item-head">
            1. {t("advantages.item1header")}
          </div>
          <div className="advantages-item-description">
            {t("advantages.item1description")}
          </div>
        </div>
        <div className="advantages-options-div">
          <div className="advantages-options-icon-div">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="120"
              viewBox="0 0 120 120"
            >
              <g
                id="Step_2"
                data-name="Step 2"
                transform="translate(-417 -1548)"
              >
                <path
                  id="Oval"
                  d="M60,0A60,60,0,1,1,0,60,60,60,0,0,1,60,0Z"
                  transform="translate(417 1548)"
                  fill="#fa7f28"
                />
                <path
                  id="Icon_material-email"
                  data-name="Icon material-email"
                  d="M48.284,6H8.032a5.025,5.025,0,0,0-5.006,5.032L3,41.221a5.046,5.046,0,0,0,5.032,5.032H48.284a5.046,5.046,0,0,0,5.032-5.032V11.032A5.046,5.046,0,0,0,48.284,6Zm0,10.063L28.158,28.642,8.032,16.063V11.032L28.158,23.611,48.284,11.032Z"
                  transform="translate(447.847 1581.874)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <div className="advantages-item-head">
            2. {t("advantages.item2header")}
          </div>
          <div className="advantages-item-description">
            {t("advantages.item2description")}
          </div>
        </div>
        <div className="advantages-options-div">
          <div className="advantages-options-icon-div">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="120"
              viewBox="0 0 120 120"
            >
              <g
                id="Step_3"
                data-name="Step 3"
                transform="translate(-417 -1548)"
              >
                <path
                  id="Oval"
                  d="M60,0A60,60,0,1,1,0,60,60,60,0,0,1,60,0Z"
                  transform="translate(417 1548)"
                  fill="#fa7f28"
                />
                <path
                  id="Icon_awesome-thumbs-up"
                  data-name="Icon awesome-thumbs-up"
                  d="M10.452,22.513H2.412A2.412,2.412,0,0,0,0,24.925V49.046a2.412,2.412,0,0,0,2.412,2.412h8.04a2.412,2.412,0,0,0,2.412-2.412V24.925A2.412,2.412,0,0,0,10.452,22.513ZM6.432,47.438a2.412,2.412,0,1,1,2.412-2.412A2.412,2.412,0,0,1,6.432,47.438ZM38.594,8.186c0,4.263-2.61,6.654-3.344,9.5H45.473a6.005,6.005,0,0,1,5.985,5.839A7.277,7.277,0,0,1,49.5,28.472l-.011.011a8.4,8.4,0,0,1-.935,7.987,7.948,7.948,0,0,1-1.646,7.513,5.335,5.335,0,0,1-.618,4.486c-2.051,2.947-7.135,2.989-11.435,2.989h-.286c-4.853,0-8.825-1.769-12.016-3.189a15.817,15.817,0,0,0-5.292-1.626,1.206,1.206,0,0,1-1.184-1.206V23.953a1.2,1.2,0,0,1,.358-.856c3.981-3.934,5.693-8.1,8.957-11.368,1.488-1.491,2.029-3.742,2.552-5.92C28.394,3.949,29.329,0,31.357,0,33.769,0,38.594.8,38.594,8.186Z"
                  transform="translate(451.271 1582.271)"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>{" "}
          <div className="advantages-item-head">
            3. {t("advantages.item3header")}
          </div>
          <div className="advantages-item-description">
            {t("advantages.item3description")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantagesComponent;
