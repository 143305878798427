import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import "./FlexibleSegment.css";
const FlexibleSegment = () => {
  const { t } = useTranslation();

  return (
    <div className="flexible-segment-container">
      <div className="flexible-segment-div">
        <div className="flexible-title">{t("flexible.header")}</div>
        <div className="flexible-description">{t("flexible.description")}</div>
        <Button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="ad-3-button"
          variant="contained"
        >
          {t("flexible.button")}
        </Button>
      </div>
    </div>
  );
};

export default FlexibleSegment;
