import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import "./BadState.css";
import ad3 from "../../../../assets/images/calculateb3.png";
import check from "../../../../assets/images/check.svg";
import "./SecAdvertise.css";
const BadState = () => {
  const { t } = useTranslation();
  return (
    <div className="ad3-container">
      <img src={ad3} className="ad-3-img" alt="" />
      <div className="ad-3-cover-container">
        <div className="ad-3-info-div">
          <h1 className="ad-3-title">{t("bad state.header")}</h1>
          <div className="ad-3-description">{t("bad state.description")}</div>
          <div className="ad-3-items-container">
            <img src={check} alt="" />
            <div className="ad-3-items-text">{t("bad state.item1")}</div>
          </div>
          <div className="ad-3-items-container">
            <img src={check} alt="" />
            <div className="ad-3-items-text">{t("bad state.item2")}</div>
          </div>
          <div className="ad-3-items-container">
            <img src={check} alt="" />
            <div className="ad-3-items-text">{t("bad state.item3")}</div>
          </div>{" "}
          <Button className="ad-3-button" variant="contained">
            CONTACT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BadState;
